import {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback
} from "react";

import { useNavigate, useLocation } from "react-router-dom";
import isEqual from "lodash/isEqual";
import { getInvoicesWithProjectId, getSortedOrdersWithProjectId } from "utils";

import {
  projectsByCompanyId,
  getOnCompanyID,
  ordersByCompanyId,
  allContacts,
  invoicesByCompanyId,
  login,
  logout,
  getDocuments,
  verifyToken,
  getSignaturesOnProjectId,
  notificationsByCompanyId,
  getUserCompanies
} from "../api";

export const APIContext = createContext({});

// const USER_OBJECT = {
//   AuthApproved: true,
//   MFAChallengeRequired: true,
//   MFAmissing: false,
//   FirstName: "Pär",
//   LastName: "Thunberg",
//   Email: "par@accomplice.se",
//   Mobile: "+46704850645",
//   CompanyID: "12228",
//   token: "test_token",
//   tokenExpiresIn: 1665392194
// };

const initialState = {
  projects: [],
  orders: [],
  contacts: "",
  invoices: [],
  userCompanies: null,
  companyId: "",
  company: "",
  notifications: [],
  companySalesPerson: "",
  user: {},
  errorMessage: "",
  isVerified: false,
  isLoadingUser: false,
  docsLoading: false
};

const ApiContextProvider = (props) => {
  const { children } = props;

  const [state, setState] = useState(() => {
    const companyId = localStorage.getItem("companyId") || "";
    const user = JSON.parse(localStorage.getItem("mtabUser") || "{}");
    return { ...initialState, companyId, user };
  });

  const navigate = useNavigate();
  const location = useLocation();

  // verifies jwt token and logout user or sets user object
  useEffect(() => {
    const asyncFunc = async () => {
      if (state.user?.token) {
        try {
          const res = await verifyToken(state.user?.token);
          if (res?.message === "success") {
            setState((prev) => ({ ...prev, isVerified: true }));
          } else {
            logoutUser();
          }
        } catch (error) {
          console.error("Token verification failed", error);
          logoutUser();
        }
      } else {
        logoutUser();
      }
    };

    asyncFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // sets projects and orders
  useEffect(() => {
    if (state.companyId && state.user?.token && state.isVerified) {
      const fetchData = async () => {
        try {
          setState((prev) => ({ ...prev, isLoadingUser: true }));

          const companyDataPromise = getOnCompanyID(
            state.companyId,
            state.user?.token
          );
          const projectsDataPromise = projectsByCompanyId(
            state.companyId,
            state.user?.token
          );
          const ordersDataPromise = ordersByCompanyId(
            state.companyId,
            state.user?.token
          );
          const contactsDataPromise = allContacts(state.user?.token);
          const invoicesDataPromise = invoicesByCompanyId(
            state.companyId,
            state.user?.token
          );
          const notificationsDataPromise = notificationsByCompanyId(
            state.companyId,
            state.user?.token
          );

          const [
            { value: projectsData },
            { value: ordersData },
            { value: contactsData },
            { value: invoicesData },
            { value: companyData },
            { value: notificationsData }
          ] = await Promise.allSettled([
            projectsDataPromise,
            ordersDataPromise,
            contactsDataPromise,
            invoicesDataPromise,
            companyDataPromise,
            notificationsDataPromise
          ]);

          // Handle errors for each promise
          if (projectsData.status === "rejected") {
            console.error("Failed to fetch projects", projectsData.reason);
          }
          // ... handle other promises similarly ...

          // computing filteredProjects
          const filteredProjects = projectsData?.map((project) => {
            const { ProjectID, ID } = project;
            const projectOrdersSorted = getSortedOrdersWithProjectId(
              ordersData,
              ProjectID
            );
            const projectInvoicesData = getInvoicesWithProjectId(
              invoicesData,
              ProjectID
            );

            return {
              ...project,
              id: ID,
              orders: projectOrdersSorted,
              invoices: projectInvoicesData
            };
          });

          setState((prev) => ({
            ...prev,
            orders: ordersData,
            contacts: contactsData,
            company: companyData?.[0],
            invoices: invoicesData,
            projects: filteredProjects,
            notifications: notificationsData,
            isLoadingUser: false
          }));
        } catch (error) {
          console.error("Error fetching all data", error);
          setState((prev) => ({ ...prev, isLoadingUser: false }));
        }
      };

      fetchData();
    }
  }, [state.companyId, state.isVerified, state.user?.token]);

  // set companySalesPerson
  useEffect(() => {
    if (state.contacts) {
      const [salesPerson] = state.contacts.filter((item) => {
        const { Userid } = item;
        return Userid === state.company?.SalesPersonID;
      });
      setState((prev) => ({ ...prev, companySalesPerson: salesPerson }));
    }
  }, [state.company?.SalesPersonID, state.contacts]);

  // set userCompanies
  useEffect(() => {
    const Id = state.user?.Id;
    const token = state.user?.token;

    if (!token || !Id) return;

    const fetchUserCompanies = async () => {
      try {
        const res = await getUserCompanies(Id, token);
        const { userCompanies = [] } = res || {};

        // If only one company, set companyId to the first company's ID
        const companyId =
          userCompanies.length === 1 ? userCompanies[0].CompanyId || "" : null;

        if (companyId) {
          localStorage.setItem("companyId", companyId);
        }

        setState((prev) => ({
          ...prev,
          userCompanies,
          ...(companyId && { companyId })
        }));
      } catch (error) {
        console.error("Error fetching user companies:", error);
      }
    };

    // Execute the function
    fetchUserCompanies();
  }, [state.user?.Id, state.user?.token]);

  // first login
  /**
   *
   * @param {string} userid
   * @param {string} password
   * @returns void
   */
  const loginUser = async (userid, password) => {
    const data = await login({ userid, password }, setState);

    if (data) {
      // this has token
      localStorage.setItem("mtabUser", JSON.stringify(data));

      setState((prev) => ({ ...prev, user: data, errorMessage: "" }));
    }
  };

  /**
   *
   * @param {string} userid
   * @param {string} password
   * @returns void
   */
  const verifyUser = async (data) => {
    if (data) {
      // this has token
      const { token, tokenExpiresIn } = data;
      const userWithToken = { ...state.user, token, tokenExpiresIn };
      setState((prev) => ({ ...prev, user: userWithToken, errorMessage: "" }));
      localStorage.setItem("mtabUser", JSON.stringify(userWithToken));
    }
  };

  /**
   *
   * @param {*} projectId
   * @returns
   */
  const getOrdersByProjectId = useCallback(
    (projectId) => {
      const projectOrdersSorted = getSortedOrdersWithProjectId(
        state.orders,
        projectId
      );

      return projectOrdersSorted;
    },
    [state.orders]
  );

  /**
   *
   * @param {*} projectId
   * @returns
   */
  const getProjectByProjectId = useCallback(
    (projectId) => {
      return state.projects?.find(
        (item) => item.ProjectID === parseInt(projectId)
      );
    },
    [state.projects]
  );

  /**
   *
   * @param {*} status
   * @returns
   */
  const getProjectsByStatus = useCallback(
    (status) => {
      const statusArray = Array.isArray(status) ? status : [status];
      return state.projects?.filter(
        (projects) =>
          statusArray.indexOf(projects?.ProjectStatus?.[0]?.StatusNo) > -1
      );
    },
    [state.projects]
  );

  /**
   * Fetches latest notifications used for polling
   */
  const getNotifications = useCallback(async () => {
    if (state.companyId && state.user?.token && state.isVerified) {
      const notificationsData = await notificationsByCompanyId(
        state.companyId,
        state.user?.token
      );

      if (!isEqual(state.notifications, notificationsData)) {
        // console.log("found " + notificationsData?.length + " notifications.");
        setState((prev) => ({ ...prev, notifications: notificationsData }));
      }
      // console.log("no-new notifications.");
    }
    return true;
  }, [
    state.companyId,
    state.user?.token,
    state.isVerified,
    state.notifications
  ]);

  /**
   *
   * @param {Array} orders
   * @param {string} projectId
   * @returns
   */
  const documents = useCallback(
    async (orders = [], projectId = "") => {
      setState((prev) => ({ ...prev, docsLoading: true }));

      const files = await getDocuments(
        orders,
        projectId,
        state.companyId,
        state.user?.token
      );

      // TODO: enable this
      const newDocs = await getSignaturesOnProjectId(
        projectId,
        state.user?.token
      );

      // project that has docs 911580
      // TODO: remove text this
      // const newDocs = await getSignaturesOnProjectId(911580, state.user?.token);
      const combinedDocs = [...files, ...newDocs];

      setState((prev) => ({ ...prev, docsLoading: false }));
      return combinedDocs;
    },
    [state.companyId, state.user?.token]
  );

  /**
   *
   * @param {*} orderId
   * @returns
   */
  const getOrderByOrderId = useCallback(
    (orderId) => {
      const foundOrder = state.orders?.find(
        (order) => order.OrderID === parseInt(orderId)
      );

      if (!foundOrder) {
        return null;
      }

      return foundOrder;
    },
    [state.orders]
  );

  /**
   *
   * @param {string} projectId
   * @returns
   */
  const getInvoicesByProjectId = useCallback(
    (projectId) => {
      // get Invoices for projects projectId
      const projectInvoicesData = getInvoicesWithProjectId(
        state.invoices,
        projectId
      );

      return projectInvoicesData;
    },
    [state.invoices]
  );

  const logoutUser = useCallback(
    (checkPath = true) => {
      localStorage.removeItem("mtabUser");
      localStorage.removeItem("token");
      localStorage.removeItem("companyId");

      // Logout on server
      if (state.user?.token) {
        logout(state.user?.token);
      }

      // Reset state
      setState(initialState);

      // TODO: Check if we are in a 'free' path (update/set password route)
      // console.log("location", location);
      if (
        location.pathname.indexOf("/account/set-password") >= 0 &&
        checkPath === true
      ) {
        navigate(location);
      } else {
        navigate("/login");
      }
    },
    [location, navigate, state.user?.token]
  );

  const setIsVerified = (verified) => {
    setState((prev) => ({ ...prev, isVerified: verified }));
  };

  return (
    <APIContext.Provider
      value={{
        ...state,
        getOrdersByProjectId,
        getProjectByProjectId,
        getProjectsByStatus,
        getOrderByOrderId,
        getNotifications,
        getInvoicesByProjectId,
        loginUser,
        verifyUser,
        logoutUser,
        setIsVerified,
        documents,
        setState
      }}
    >
      {children}
    </APIContext.Provider>
  );
};

/**
 *
 * @returns {{
 * projects: Array,
 * companySalesPerson: Object,
 * company: Object,
 * orders: Array,
 * invoices: Array,
 * isVerified: boolean,
 * notifications: Array,
 * user: Object,
 * errorMessage: string,
 * isLoadingUser: boolean,
 * docsLoading: boolean,
 * getOrdersByProjectId: function,
 * getProjectByProjectId: function,
 * getProjectsByStatus: function,
 * getOrderByOrderId: function,
 * getNotifications: function,
 * getInvoicesByProjectId: function,
 * loginUser: function,
 * verifyUser: function,
 * logoutUser: function,
 * setIsVerified: function,
 * documents: function
 * }}
 */
export function useApi() {
  const context = useContext(APIContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}

export default ApiContextProvider;
