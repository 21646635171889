import { useEffect, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { Layout, ScrollHandler } from "../components";
import AppRoutes from "../AppRoutes";
import styles from "./App.scss";
import { CircularProgress } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: `${styles.primaryColor}`
    },
    secondary: {
      main: `${styles.secondaryColor}`
    },
    tertiary: {
      main: `${styles.tertiaryColor}`
    }
  },
  typography: {
    htmlFontSize: +styles.bodyTextSize.replace("px", ""), // should be number
    fontFamily: `${styles.mainFont}`,
    h1: {
      fontWeight: 400,
      fontSize: `${styles.headingSize}`,
      lineHeight: "140%",
      marginBottom: "1rem"
    },
    h2: {
      fontSize: `${styles.headingMdSize}`,
      lineHeight: "110%"
    },
    button: {
      fontSize: `${styles.bodyTextNormalSize}`,
      fontWeight: 400
    }
  }
});

// here app catches the suspense from page in case translations are not yet loaded
export default function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ScrollHandler />
      <Layout>
        {loading ? (
          <div className={"login-container centered"}>
            <CircularProgress size={100} />
          </div>
        ) : (
          <AppRoutes />
        )}
      </Layout>
    </ThemeProvider>
  );
}

export { theme };
