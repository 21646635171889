import { Navigate, Route, Routes } from "react-router-dom";
import { useApi } from "context/context";

import {
  Login,
  Home,
  Invoices,
  Project,
  Order,
  Account,
  NotFound,
  SetPassword
} from "./pages";

const AppRoutes = () => {
  const { companyId, isVerified } = useApi();

  if (companyId && isVerified) {
    return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/profile" element={<Account />} />
        <Route path="/account/set-password" element={<SetPassword />} />
        <Route path="/account/set-password/:userId" element={<SetPassword />} />
        <Route path="/invoices" element={<Invoices />} />
        <Route path="/project/:projectId" element={<Project />} />
        <Route path="/order/:orderId" element={<Order />} />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/profile" element={<Account />} />
      <Route path="/account/set-password" element={<SetPassword />} />
      <Route path="/account/set-password/:userId" element={<SetPassword />} />
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default AppRoutes;
