import { useMemo, useState } from "react";
import { Document } from "../../assets/icons";
import "./TableList.scss";

const TableList = (props) => {
  const { data = [], columns = [], pageSize = 10, type = "invoices" } = props;
  const [page, setPage] = useState(1);

  const pagedData = useMemo(
    () =>
      data
        .sort((a, b) => (a.InvoiceDate < b.InvoiceDate ? 1 : -1))
        .slice(0, pageSize * page),
    [data, page, pageSize]
  );

  return (
    <div className="table-list">
      <table className="table-list__container">
        <thead>
          <tr>
            {columns.map((a) => (
              <th key={a.title}>{a.title}</th>
            ))}
            <th></th>
          </tr>
        </thead>
        {data.length > 0 ? (
          <tbody>
            {pagedData.map((row, i) => (
              <tr key={row.ID + "_" + i}>
                {columns.map((col, y) =>
                  col.modifier ? (
                    <td key={col.name + row.ID + "_" + y}>
                      {col.modifier(row[col.name])}
                    </td>
                  ) : (
                    <td key={col.name + row.ID + "_" + y}>{row[col.name]}</td>
                  )
                )}
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan={columns.length} className="no-found">
                <span className={"td-icon"}>
                  <Document />
                </span>{" "}
                <span>No {type} found.</span>
              </td>
            </tr>
          </tbody>
        )}
      </table>
      {(page + 1) * pageSize < data.length ? (
        <button
          className="load-more"
          onClick={() => {
            setPage(page + 1);
          }}
        >
          Show more
        </button>
      ) : null}
    </div>
  );
};

export default TableList;
