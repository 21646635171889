import mtabApi from "./mtabApi";

import { BASE_URL } from "../config";

/**
 *
 * @param {string} path
 * @returns
 */
const fileDownload = (path, service, filename) => {
  if (path) {
    const url = new URL(`${BASE_URL}/file`);
    url.searchParams.set("path", path);
    if (service) {
      url.searchParams.set("service", service);
    }
    if (filename) {
      url.searchParams.set("filename", filename);
    }
    return url.toString();
  }
  return "";
};

/**
 *
 * @param {object} formData
 * @param {string} token
 * @returns
 */
const fileUpload = async (formData, token) => {
  try {
    const resp = await mtabApi({
      method: "post",
      url: "/upload",
      data: formData,
      headers: {
        Authorization: `token ${token}`,
        "Content-Type": "multipart/form-data"
      }
    });
    return resp;
  } catch (error) {
    return error;
  }
};

/**
 *
 * @param {object} data
 * @param {string} token
 * @returns
 */
const deleteFile = async (data, token) => {
  // need to clear custom data
  const { file, Url, columns, ...rest } = data;

  try {
    const resp = await mtabApi({
      method: "post",
      url: "/upload/update",
      data: rest,
      headers: {
        Authorization: `token ${token}`,
        "content-type": "application/json"
      }
    });

    return resp;
  } catch (error) {
    return error;
  }
};

export { fileDownload, fileUpload, deleteFile };
